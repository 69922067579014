import React from "react";
import "./index.css";

const Contact = () => {
    return (
        <main>
        <div style = {{height: 100 }}></div>
        <div className= "page">
            <div className="box box1">
            <div class="container">

            </div>
            </div>
        </div>
        </main>
    );
};
export default Contact