import React from "react";
import styles from "../pages.module.css"
const Epk = () => {
    return (
        <main>
        <div style = {{height: 150 }}></div>
        <div className="page">
        <div className="box box1">
        <div className>
        <p className ={styles.bio}>Sofia and the Spectrum began as a joke. <br></br> <br></br>

“If I fronted a band, I’d call it Sofia and the Spectrum”, said Sofia, because she’s on the spectrum. Everyone laughed. She laughed.
<br></br><br></br>
And then she thought a little harder about it. 
<br></br><br></br>
Sofia and the Spectrum is music for people who think just a little too hard about things. 
It’s what happens when a steady diet of confessional singer-songwriter pop lyricism meets a lifelong fascination with the blues and its relatives. 
Every song is full of visceral, theatrical intensity, the product of ruminating too long over every little thought and emotion, paper and pen in hand. 
<br></br><br></br>


</p>
</div>
<div className = "page-element">
<iframe src="https://www.youtube.com/embed/f0Tqf8pI_Rs"  title="go"></iframe>
<br></br>
<iframe src="https://www.youtube.com/embed/3huHKGMSJuE" title="pos"></iframe>
</div>
        </div> </div>
        </main>
    );
};
export default Epk